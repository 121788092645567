<template>
  <div class="uk-form-stacked">
    <div v-if="!loadingData">
      <alert-status
        v-if="listExportHub.data.length < 1"
        status="warning"
        label="Must add Export Hub first if you want to add this facility."
      />
      <alert-status
        v-if="listHulling.data.length < 1"
        status="warning"
        label="Must add Hulling Facility first if you want to add this facility."
      />
      <alert-status
        v-if="listExportHub.data.length < 1 && listHulling.data.length < 1"
        status="warning"
        label="Must add Export Hub and add Hulling Facility first if you want to add this facility."
      />
    </div>
    <div
      class="uk-child-width-expand@s uk-grid-medium"
      uk-grid
    >
      <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              VCF Code
            </label>
            <div class="uk-form-controls uk-flex">
              <div class="uk-width-1-2 uk-margin-right">
                <input
                  :value="vc_code"
                  name="vc_code"
                  class="uk-input"
                  type="text"
                  placeholder="VC Code"
                  disabled
                >
              </div>
              <div class="uk-width-1-2">
                <input
                  v-model="formPlantation.vcf_code"
                  v-validate="'required|max:50'"
                  name="vcf_code"
                  class="uk-input"
                  type="text"
                  autocomplete="off"
                  placeholder="Enter VCF Code"
                  :disabled="export_hub == '' || hulling_facility == '' || pulping_facility == ''"
                  :class="{'uk-form-danger': errors.has('vcf_code')}"
                >
                <span
                  v-show="errors.has('vcf_code')"
                  class="uk-text-small uk-text-danger"
                >{{ errors.first('vcf_code') }}</span>
              </div>
            </div>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Export Hub
            </label>
            <div class="uk-form-controls">
              <multiselect
                v-model="export_hub"
                v-validate="'required'" 
                label="name" 
                name="export_hub" 
                track-by="id" 
                placeholder="Select Export Hub or Type to Search" 
                open-direction="bottom" 
                :options="listExportHub.data"
                :searchable="true"
                :max-height="200"
                :show-no-results="true"
                @search-change="(input) => handleSearchDropdown('export', listExportHub.data, input)"
                @remove="() => recallApiList('export', false)"
                @select="(data) => recallApiList('export', data)"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
            </div>
            <div v-if="!loadingData">
              <span
                v-if="listExportHub.data.length < 1"
                class="uk-text-medium uk-text-danger"
              >
                No Export Hub data. Please add Export Hub first.
              </span>
            </div>
            <span
              v-show="errors.has('export_hub')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('export_hub') }}</span>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Pulping Facility
            </label>
            <div class="uk-form-controls">
              <multiselect 
                v-model="pulping_facility"
                v-validate="'required'"
                label="facility_name"
                name="pulping_facility"
                track-by="id"
                placeholder="Select Pulping Facility or Type to Search"
                open-direction="bottom"
                :options="listPulping.data"
                :searchable="true"
                :max-height="200"
                :show-no-results="true"
                @search-change="(input) => handleSearchDropdown('pulping', listPulping.data, input)"
                @remove="() => recallApiList('pulping', false)"
                @select="(data) => recallApiList('pulping', data)"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
            </div>
            <div v-if="!loadingData">
              <span
                v-if="listPulping.data.length < 1"
                class="uk-text-medium uk-text-danger"
              >
                No Pulping Facility data. Please add Pulping Facility first.
              </span>
            </div>
            <span
              v-show="errors.has('pulping_facility')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('pulping_facility') }}</span>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Species Type
            </label>
            <div class="uk-form-controls">
              <multiselect 
                v-model="species"
                v-validate="'required'"
                label="name"
                name="species_type"
                track-by="id"
                placeholder="Select Species Type"
                open-direction="bottom"
                :options="listSpeciesType"
                :searchable="true"
                :max-height="200"
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
            </div>
            <span
              v-show="errors.has('species_type')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('species_type') }}</span>
          </div>

          <div class="uk-width-1-1">
            <div class="uk-form-label">
              Size
            </div>
            <div class="uk-width-1-1 fields-custom">
              <input
                v-model="formPlantation.size"
                v-validate="'required|max:50'"
                name="size"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Size"
                onkeypress="return /^[0-9 +]*$/i.test(event.key)"
                :class="{'uk-form-danger': errors.has('size')}"
              >
              <label class="label-custom">Hectare</label>
            </div>
            <span
              v-show="errors.has('size')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('size') }}</span>
          </div>
          
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Latitude
            </label>
            <div class="uk-form-controls">
              <input
                v-model="formPlantation.latitude"
                v-validate="'required|max:100'"
                name="latitude"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Latitude"
                :class="{'uk-form-danger': errors.has('latitude')}"
              >
            </div>
            <span
              v-show="errors.has('latitude')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('latitude') }}</span>
          </div>

          <div class="uk-width-1-1">
            <div class="uk-form-label">
              Potensi Panen
            </div>
            <div class="uk-width-1-1 fields-custom">
              <input
                v-model="formPlantation.harvest_potential"
                v-validate="'required|max:10'"
                name="harvest_potential"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Size"
                onkeypress="return /^[0-9 +]*$/i.test(event.key)"
                :class="{'uk-form-danger': errors.has('harvest_potential')}"
              >
              <label class="label-custom">Kg</label>
            </div>
            <span
              v-show="errors.has('harvest_potential')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('harvest_potential') }}</span>
          </div>
        </div>
      </div>
          
      <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l">
        <div
          class="uk-child-width-expand@s uk-grid-medium"
          uk-grid
        >
          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Facility Name
            </label>
            <div class="uk-form-controls">
              <input
                v-model="formPlantation.facility_name"
                v-validate="'required|max:50'"
                name="facility_name"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Facility Name"
                :class="{'uk-form-danger': errors.has('facility_name')}"
              >
            </div>
            <span
              v-show="errors.has('facility_name')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('facility_name') }}</span>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Hulling Facility
            </label>
            <div class="uk-form-controls">
              <multiselect 
                v-model="hulling_facility"
                v-validate="'required'"
                label="facility_name"
                name="hulling_facility"
                track-by="id"
                placeholder="Select Hulling Facility or Type to Search"
                open-direction="bottom"
                :options="listHulling.data"
                :searchable="true"
                :max-height="200"
                :show-no-results="true"
                @search-change="(input) => handleSearchDropdown('hulling', listHulling.data, input)"
                @remove="() => recallApiList('hulling', false)"
                @select="(data) => recallApiList('hulling', data)"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
            </div>
            <div v-if="!loadingData">
              <span
                v-if="listHulling.data.length < 1"
                class="uk-text-medium uk-text-danger"
              >
                No Hulling Facility data. Please add Hulling Facility first.
              </span>
            </div>
            <span
              v-show="errors.has('hulling_facility')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('hulling_facility') }}</span>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Varieties Type
            </label>
            <div class="uk-form-controls">
              <multiselect 
                v-model="varieties"
                v-validate="'required'"
                label="name"
                name="varieties_type"
                track-by="id"
                placeholder="Select Varieties Type"
                open-direction="bottom"
                :options="listVarietiesType"
                :searchable="true"
                :max-height="200"
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
            </div>
            <span
              v-show="errors.has('varieties_type')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('varieties_type') }}</span>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Product Type
            </label>
            <div class="uk-form-controls">
              <multiselect 
                v-model="product_type"
                v-validate="'required'"
                label="name"
                name="product_type"
                track-by="id"
                placeholder="Select Product Type"
                open-direction="bottom"
                :options="listProductType"
                :searchable="true"
                :max-height="200"
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
            </div>
            <span
              v-show="errors.has('product_type')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('product_type') }}</span>
          </div>

          <div class="uk-width-1-1">
            <div class="uk-form-label">
              Tree
            </div>
            <div class="uk-width-1-1 fields-custom">
              <input
                v-model="formPlantation.tree"
                v-validate="'required|max:50'"
                name="tree"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Tree"
                onkeypress="return /^[0-9 +]*$/i.test(event.key)"
                :class="{'uk-form-danger': errors.has('tree')}"
              >
              <label class="label-custom">Tree</label>
            </div>
            <span
              v-show="errors.has('tree')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('tree') }}</span>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Longitude
            </label>
            <div class="uk-form-controls">
              <input
                v-model="formPlantation.longitude"
                v-validate="'required|max:100'"
                name="longitude"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Longitude"
                :class="{'uk-form-danger': errors.has('longitude')}"
              >
            </div>
            <span
              v-show="errors.has('longitude')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('longitude') }}</span>
          </div>

          <div class="uk-inline uk-width-1-1">
            <label class="uk-form-label">
              Address
            </label>
            <div class="uk-form-controls">
              <input
                v-model="formPlantation.address"
                v-validate="'required|max:100'"
                name="address"
                class="uk-input"
                type="text"
                autocomplete="off"
                placeholder="Enter Address"
                :class="{'uk-form-danger': errors.has('address')}"
              >
            </div>
            <span
              v-show="errors.has('address')"
              class="uk-text-small uk-text-danger"
            >{{ errors.first('address') }}</span>
          </div>
        </div>
      </div>
    </div>
                
    <div class="uk-flex uk-flex-right uk-margin-medium-top">
      <button
        class="uk-button soft-green uk-margin-right"
        @click="showCancelConfirmModal"
      >
        Cancel
      </button>
      <button
        class="uk-button green"
        :class="isCanAccessUser('add', 'PLANTATION') ? '' : 'disabled'"
        :disabled="!isCanAccessUser('add', 'PLANTATION')"
        @click="showSaveConfirmModal"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import AlertStatus from '@/components/globals/AlertStatus'
import { currencyDigit } from '@/utils/formater'
import { isCanAccess } from '@/utils/auth'
import { mapActions } from 'vuex'

export default {
  components: {
    AlertStatus
  },
  props: {
    images: {
      required: true,
      type: String
    },
    loadingData:{
      type: Boolean
    },
    listVarietiesType: {
      required: true,
      type: Array
    },
    listSpeciesType: {
      required: true,
      type: Array
    },
    listProductType: {
      required: true,
      type: Array
    },
    listExportHub: {
      required: true,
      type: Object
    },
    listHulling: {
      required: true,
      type: Object
    },
    listPulping: {
      required: true,
      type: Object
    },
    setToggleModalAddEditDiscard: {
      required: true,
      type: Function
    },
    getToggleModalAddEditDiscard: {
      required: true,
      type: Object
    },
    setDataModalAddEdit: {
      required: true,
      type: Function
    }
  },
  data() {
    return{
      vc_code: '',
      export_hub_code: '',
      hulling_facility_code: '',
      pulping_facility_code: '',
      export_hub: '',
      hulling_facility: '',
      pulping_facility: '',
      species: '',
      varieties: '',
      product_type: '',
      searchOn: false,
      formPlantation: {
        vcf_code: '',
        facility_name: '',
        latitude: '',
        longitude: '',
        export_hub_id: '',
        hulling_facility_id: '',
        pulping_facility_id: '',
        address: '',
        species_id: '',
        varieties_id: '',
        product_type_id: '',
        harvest_potential: '',
        size: '',
        tree: ''
      }
    }
  },
  methods: {
    ...mapActions({
      actionGetListHulling: 'hullingFacility/getListHulling',
      actionGetListPulping: 'pulpingFacility/getListPulping',
      actionGetListExportHub: 'exportHub/getListExportHub'
    }),
    async handleSearchDropdown(list, data, input) {
      const dataList = data
  
      const checker = dataList.some((item) => {
        const lowerFacilityName = item.facility_name ? item.facility_name.toLowerCase() : item.name.toLowerCase()
        const lowerSearchQuery = input.toLowerCase()
        return lowerFacilityName.includes(lowerSearchQuery)
      })

      if (!checker && !(dataList.length < 1)) {
        this.searchOn = true
        switch (list) {
        case 'hulling':
          await this.actionGetListHulling({ search: input })
          break
        case 'pulping':
          await this.actionGetListPulping({ hulling_facility_id: this.hulling_facility.id, search: input })
          break
        default:
          await this.actionGetListExportHub({ search: input })
          break
        }
      }

      if (!input) {
        let i = input
        if(!(i.includes(input)) || dataList.length < 1) {
          switch (list) {
          case 'hulling':
            await this.actionGetListHulling({ export_hub_id: this.export_hub, page: 1, limit: 50 })
            break
          case 'pulping':
            await this.actionGetListPulping({hulling_facility_id: this.hulling_facility.id, page: 1, limit: 50 })
            break
          default:
            await this.actionGetListExportHub({ page: 1, limit: 50 })
            break
          }
        }
      }
    },
    async recallApiList(list, data){
      if(this.searchOn){
        this.searchOn = false
        switch (list) {
        case 'hulling':
          await this.actionGetListHulling({ export_hub_id: this.export_hub, page: 1, limit: 50 })
          break
        case 'pulping':
          await this.actionGetListPulping({ hulling_facility_id: this.hulling_facility.id, page: 1, limit: 50 })
          break
        default:
          await this.actionGetListExportHub({ page: 1, limit: 50 })
          break
        }
      } else {
        if(data){
          switch (list) {
          case 'hulling':
            await this.actionGetListPulping({hulling_facility_id: data.id})
            this.export_hub = this.listExportHub.data.filter(item => item.id == data.export_hub.id)[0]
            this.vc_code = this.listHulling.data.filter(item => item.export_hub_id == data.id)[0].evc_code
            break
          case 'pulping':
            this.export_hub = this.listExportHub.data.filter(item => item.id == data.export_hub.id)[0]
            this.hulling_facility = this.listHulling.data.filter(item => item.id == data.hulling_facility.id)[0]
            this.vc_code = data.vc_code
            break
          default:
            await this.actionGetListHulling({export_hub_id: data.id})
            this.hulling_facility = this.listHulling.data.filter(item => item.export_hub_id == data.id)[0]
            await this.actionGetListPulping({hulling_facility_id: this.hulling_facility.id})
            this.pulping_facility = null
            this.vc_code = this.listHulling.data.filter(item => item.export_hub_id == data.id)[0].vc_code
            break
          }
        } else {
          switch (list) {
          case 'hulling':
            this.pulping_facility = null
            await this.actionGetListHulling({ page: 1, limit: 50 })
            break
          case 'pulping':
            this.hulling_facility = null
            this.export_hub = null
            await this.actionGetListPulping({ page: 1, limit: 50 })
            break
          default:
            this.hulling_facility = null
            this.pulping_facility = null
            await this.actionGetListExportHub({ page: 1, limit: 50 })
            break
          }
        }
      }
    },
    isCanAccessUser(type, menu) {
      return isCanAccess(type, menu)
    },
    formatCurrency (val) {
      return currencyDigit(val)
    },
    showCancelConfirmModal() {
      this.setToggleModalAddEditDiscard({
        targetModals: true,
        title: 'Cancel Confirmation', 
        description: 'All data will be lost if you leave. Are you sure want to leave?',
        customTitleBtnOk: 'Yes',
        customTitleBtnCancel: 'No',
        type: 'discard',
        route: 'Plantation',
        loadingTable: false,
        loadingPopUp: false
      })
    },
    showSaveConfirmModal() {
      this.formPlantation.export_hub_id = this.export_hub ? this.export_hub?.id : ''
      this.formPlantation.hulling_facility_id = this.hulling_facility ? this.hulling_facility?.id : ''
      this.formPlantation.pulping_facility_id = this.pulping_facility ? this.pulping_facility?.id : ''

      this.formPlantation.varieties_id = this.varieties ? this.varieties?.id : ''
      this.formPlantation.species_id = this.species ? this.species?.id : ''
      this.formPlantation.product_type_id = this.product_type ? this.product_type?.id : ''
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.setDataModalAddEdit(this.formPlantation)
          this.setToggleModalAddEditDiscard({
            targetModals: true,
            title: 'Save Confirmation', 
            description: 'Are you sure want to save this data?',
            customTitleBtnOk: 'Save',
            customTitleBtnCancel: 'Cancel',
            type: 'save',
            loadingTable: false,
            loadingPopUp: false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  .uk-form-label {
    font-family: 'interRegular';
    font-weight: 400;
    font-size: 16px !important;
    color: #000000;
    text-transform: none;
  }
  .fields-custom{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .label-custom{
    position: absolute;
    right: 1rem;
  }
  .uk-input:disabled{
    background: #F4F4F4 !important;
    color: #000000 !important;
  }
</style>
