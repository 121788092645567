<template>
  <div
    class="alert-label uk-margin-bottom"
  >
    <div class="uk-margin-small-right">
      <img 
        :src="`${images}/icon/ic_warning.svg`"
        alt=""
        loading="lazy"
      >
    </div>
    <div>
      {{ label || '' }}
    </div>
  </div>
</template>

<script>
import { PREFIX_IMAGE } from "@/utils/constant"

export default {
  props: {
    label: {
      required: false,
      default: '',
      type: String
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE
    }
  }
}
</script>

<style scoped>
.alert-label {
  min-width: 500px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'interMedium';
  font-weight: 500;
  padding: 12px 16px;
  color: #EB5757;
  background: #FFF1F1;
  border-radius: 8px;
}
</style>
