<template>
  <div>
    <warning
      v-if="status == 'warning'"
      :label="label"
    />
  </div>
</template>

<script>
import Warning from '@/components/globals/alert/warning'

export default {
  components: {
    Warning
  },
  props: {
    status: { 
      required: true, 
      type: String 
    },
    label: { 
      required: true, 
      type: String 
    }
  }
}
</script>
